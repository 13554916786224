import 'simplebar/dist/simplebar.min.css'
import 'nprogress/nprogress.css'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql
} from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Toaster } from 'react-hot-toast'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import { AuthProvider } from './contexts/jwt-auth-context'
import { SettingsProvider } from './contexts/settings-context'
import { App } from './app'

const client = new ApolloClient({
  uri: process.env.REACT_APP_END_POINT,
  cache: new InMemoryCache()
})

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <AuthProvider>
                <App />
                <Toaster position="top-center" />
              </AuthProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </ApolloProvider>
      </BrowserRouter>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
)
