import { request, gql, GraphQLClient } from 'graphql-request'
import { subDays, subHours, subMinutes } from 'date-fns'
import { throttle } from '../config'
import { applyFilters } from '../utils/apply-filters'
import { applyPagination } from '../utils/apply-pagination'
import { applySort } from '../utils/apply-sort'
import { wait } from '../utils/wait'

const endpoint = process.env.REACT_APP_END_POINT
const client = new GraphQLClient(endpoint)

const allServersQuery = gql`
  query getDevicesWo($organizationId: UUID!) {
    allServers(condition: { organizationId: $organizationId }) {
      nodes {
        licensed
        label
        activated
        attributes
        createdAt
        deviceId
        deviceMode
        faceDetection
        hostIp
        mqPort
        mqSubTopic
        mqTopic
        organizationId
        serialNumber
        sseToken
        sseUrl
        tags
        online
      }
    }
  }
`

const serverBySerial = gql`
  query getServerById($serialNumber: String!) {
    serverBySerialNumber(serialNumber: $serialNumber) {
      activated
      attributes
      cameraAlphaStatus
      cameraBetaStatus
      createdAt
      deviceId
      deviceMode
      faceDetection
      hostIp
      label
      mqPort
      mqSubTopic
      mqTopic
      serialNumber
      tags
    }
  }
`

const getServerByDeviceId = gql`
  query MyQuery4($deviceId: UUID!) {
    serverByDeviceId(deviceId: $deviceId) {
      deploymentsByServerId(first: 1, orderBy: CREATED_AT_DESC) {
        nodes {
          createdAt
          modelByFoodModelId {
            id
          }
        }
      }
    }
  }
`

const registerDeviceQuery = gql`
  mutation RegisterDevice($orgId: UUID!, $serialNumber: String!) {
    associateDevice(input: { serialNumber: $serialNumber, orgId: $orgId }) {
      server {
        serialNumber
      }
    }
  }
`

const updateGeneralInfoQuery = gql`
  mutation updateGeneralInfo(
    $serialNumber: String!
    $label: String!
    $tags: [String]!
    $deviceId: UUID!
  ) {
    updateServerBySerialNumber(
      input: {
        serverPatch: { label: $label, tags: $tags, deviceId: $deviceId }
        serialNumber: $serialNumber
      }
    ) {
      server {
        serialNumber
      }
    }
  }
`

const updateConfigQuery = gql`
  mutation UpdateConfig(
    $serialNumber: String!
    $mqPort: String!
    $mqSubTopic: String!
    $mqTopic: String!
    $sseUrl: String!
    $faceDetection: Boolean!
    $deviceMode: DeviceModes!
  ) {
    updateServerBySerialNumber(
      input: {
        serverPatch: {
          mqPort: $mqPort
          mqSubTopic: $mqSubTopic
          mqTopic: $mqTopic
          sseUrl: $sseUrl
          faceDetection: $faceDetection
          deviceMode: $deviceMode
        }
        serialNumber: $serialNumber
      }
    ) {
      server {
        serialNumber
      }
    }
  }
`

const getItemUrl = gql`
  query getItemUrl($organizationId: UUID!) {
    organizationById(id: $organizationId) {
      itemApiUrl
      name
    }
  }
`

const orgIdQuery = gql`
  query OrgName($userId: UUID!) {
    orgUserByUserId(userId: $userId) {
      organizationId
      userId
    }
  }
`

const deviceStatusQuery = gql`
  mutation UpdateDeviceStatus($serialNumber: String!, $activated: Boolean!) {
    updateServerBySerialNumber(
      input: {
        serverPatch: { activated: $activated }
        serialNumber: $serialNumber
      }
    ) {
      server {
        serialNumber
      }
    }
  }
`

const updateOrgConfig = gql`
  mutation updateOrgConfig($id: UUID!, $name: String!, $itemApiUrl: String!) {
    upsertOrganizationById(
      input: {
        organization: { id: $id, name: $name }
        organizationPatch: { itemApiUrl: $itemApiUrl }
      }
    ) {
      clientMutationId
      organization {
        itemApiUrl
      }
    }
  }
`

function genAuthHeader() {
  const accessToken = window.localStorage.getItem('accessToken')
  const requestHeaders = {
    authorization: 'Bearer '.concat(accessToken)
  }
  return requestHeaders
}

class ProductApi {
  async getProducts(options) {
    console.log(options)
    if (throttle) {
      await wait(throttle)
    }
    const orgId = localStorage.getItem('organizationId')
    const res = await client.request(
      allServersQuery,
      { organizationId: orgId },
      genAuthHeader()
    )
    const { filters, sort, sortBy, page, query, view } = options

    /*
     NOTE: Query, filter, sort and pagination are operation meant to be executed on the server.
     Since this does not connect to a real backend, we simulate these operations.
     */

    const queriedProducts = res.allServers.nodes.filter((_product) => {
      if (
        !!query &&
        !_product.name.toLowerCase().includes(query.toLowerCase())
      ) {
        return false
      }

      // No need to look for any resource fields
      if (typeof view === 'undefined' || view === 'all') {
        return true
      }

      // In this case, the view represents the resource status
      return _product.status === view
    })
    const filteredProducts = applyFilters(queriedProducts, filters)
    const sortedProducts = applySort(filteredProducts, sort, sortBy)
    const paginatedProducts = applyPagination(sortedProducts, page)

    return Promise.resolve({
      products: paginatedProducts,
      productsCount: filteredProducts.length
    })
  }

  async getProduct() {
    if (throttle) {
      await wait(throttle)
    }

    return Promise.resolve(product)
  }

  async getProductBySerial(serial) {
    const res = await client.request(
      serverBySerial,
      { serialNumber: serial },
      genAuthHeader()
    )

    if (res.serverBySerialNumber === null) {
      return {}
    }
    console.log(res)
    if (throttle) {
      await wait(throttle)
    }
    // const device = products.filter((prod) => prod.serial === serial);
    return Promise.resolve(res.serverBySerialNumber)
  }

  async getModelByDeviceId(deviceId) {
    const res = await client.request(
      getServerByDeviceId,
      { deviceId: deviceId },
      genAuthHeader()
    )

    if (res.serverByDeviceId === null) {
      return {}
    }
    console.log(res)
    if (throttle) {
      await wait(throttle)
    }
    return Promise.resolve(res.serverByDeviceId.deploymentsByServerId)
  }

  async updateGeneralInfo(values) {
    const res = await client.request(
      updateGeneralInfoQuery,
      values,
      genAuthHeader()
    )

    if (res.serverBySerialNumber === null) {
      return {}
    }
    console.log(res)
    if (throttle) {
      await wait(throttle)
    }
    return Promise.resolve(res)
  }

  async updateConfig(values) {
    const res = await client.request(updateConfigQuery, values, genAuthHeader())

    if (res.serverBySerialNumber === null) {
      return {}
    }
    console.log(res)
    if (throttle) {
      await wait(throttle)
    }
    return Promise.resolve(res)
  }

  async updateStatus(values) {
    const res = await client.request(deviceStatusQuery, values, genAuthHeader())

    if (res.serverBySerialNumber === null) {
      return {}
    }
    console.log(res)
    if (throttle) {
      await wait(throttle)
    }
    return Promise.resolve(res)
  }

  async updateOrgConfig(values) {
    const res = await client.request(updateOrgConfig, values, genAuthHeader())

    localStorage.setItem(
      'itemApiUrl',
      res.upsertOrganizationById.organization.itemApiUrl
    )

    if (throttle) {
      await wait(throttle)
    }
    return Promise.resolve(res)
  }

  async getOrgId() {
    const userId = window.localStorage.getItem('userId')
    const res = await client.request(orgIdQuery, { userId }, genAuthHeader())

    if (res.serverBySerialNumber === null) {
      return {}
    }
    console.log(res)
    if (throttle) {
      await wait(throttle)
    }
    localStorage.setItem('organizationId', res.orgUserByUserId.organizationId)
    return Promise.resolve(res.orgUserByUserId)
  }

  async getOrgItemApiUrl() {
    const organizationId = window.localStorage.getItem('organizationId')
    const res = await client.request(
      getItemUrl,
      { organizationId },
      genAuthHeader()
    )

    if (res.organizationById === null) {
      return {}
    }
    if (throttle) {
      await wait(throttle)
    }

    return Promise.resolve(res)
  }

  async registerDevice(values) {
    const res = await client.request(
      registerDeviceQuery,
      values,
      genAuthHeader()
    )

    if (res.serverBySerialNumber === null) {
      return {}
    }
    console.log(res)
    if (throttle) {
      await wait(throttle)
    }
    return Promise.resolve(res.associateDevice)
  }
}

export const productApi = new ProductApi()
