import PropTypes from 'prop-types';

export const Logo = (props) => {
  const { emblemOnly, variant } = props;

  const color = variant === 'light' ? '#ffffff' : '#1D262D';

  return (
    <img
      src="/static/amniscient_full.png"
      width="320"
      alt=""
    />
  );
};

Logo.defaultProps = {
  emblemOnly: false,
  variant: 'dark'
};

Logo.propTypes = {
  emblemOnly: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'dark'])
};
