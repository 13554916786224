import * as React from 'react';
import {
  Grid
} from "@material-ui/core";
import {
  makeStyles
} from "@material-ui/styles";
import {IFrame} from "./Termly";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "2rem"
  },
  // paper: {
  //   // padding: theme.spacing(2),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // },
  // heading: {
  //   fontSize: theme.typography.pxToRem(18),
  //   fontWeight: theme.typography.fontWeightRegular,
  // },

}));

export default function Term() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        item
        lg={12}
        sm={12}
        xl={12}
        xs={12}
      >
      </Grid>
 <div>
   <IFrame>
     <iframe src="https://app.termly.io/document/terms-of-use-for-website/cb2ebc94-d184-4bd3-baea-b34befe7a600" width="100%" height="1200px"/>
   </IFrame>
 </div>
    </div>
  );
}

