import { request, gql, GraphQLClient } from 'graphql-request'
import { generateResourceId } from '../utils/generate-resource-id'
import { sign, decode, JWT_SECRET, JWT_EXPIRES_IN } from '../utils/jwt'
import { wait } from '../utils/wait'

const users = [
  {
    id: '5e86809283e28b96d2d38537',
    email: 'admin@example.com',
    name: 'Amniscient',
    password: 'password'
  }
]

const endpoint = process.env.REACT_APP_END_POINT
const client = new GraphQLClient(endpoint)

const query = gql`
  mutation AuthenticateUser($email: String!, $password: String!) {
    authenticate(input: { email: $email, password: $password }) {
      jwtToken
    }
  }
`

const createOrgQuery = gql`
  mutation MyMutation(
    $adminEmail: String!
    $adminPassword: String!
    $organizationName: String!
    $storageAccountBucket: String!
    $storageAccountKey: String!
    $storageAccountSecret: String!
    $storageAccountUrl: String!
  ) {
    registerOrganization(
      input: {
        adminEmail: $adminEmail
        adminPassword: $adminPassword
        organizationName: $organizationName
        storageAccountUrl: $storageAccountUrl
        storageAccountKey: $storageAccountKey
        storageAccountSecret: $storageAccountSecret
        storageAccountBucket: $storageAccountBucket
      }
    ) {
      organization {
        id
      }
    }
  }
`

const getOrgId = async (email, headerToken) => {
  const fetchOrgIdQuery = gql`
    query getOrganizationId($email: String!) {
      orgUserByEmail(email: $email) {
        organizationByOrganizationId {
          name
          id
        }
      }
    }
  `

  const variable = { email }
  const requestHeaders = {
    authorization: `Bearer ${headerToken}`
  }
  request(endpoint, fetchOrgIdQuery, variable, requestHeaders).then(
    (response) => {
      localStorage.setItem(
        'organizationId',
        response.orgUserByEmail.organizationByOrganizationId.id
      )
      localStorage.setItem(
        'organizationName',
        response.orgUserByEmail.organizationByOrganizationId.name
      )
    },
    (error) => {
      console.log('getOrgId error', error)
    }
  )
}

class AuthApi {
  async login({ email, password }) {
    const variables = {
      email,
      password
    }

    const res = await request(endpoint, query, variables)

    return new Promise((resolve, reject) => {
      try {
        if (res.authenticate.jwtToken === null) {
          reject(new Error('Please check your email and password'))
          return
        }

        // Create the access token
        const accessToken = res.authenticate.jwtToken
        getOrgId(email, accessToken)
        resolve(accessToken)
      } catch (err) {
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }

  async createOrg(values) {
    const res = await client.request(createOrgQuery, values)

    if (res.serverBySerialNumber === null) {
      return {}
    }
    console.log(res)
    return Promise.resolve(res)
  }

  async register({ email, name, password }) {
    await wait(1000)

    return new Promise((resolve, reject) => {
      try {
        // Check if a user already exists
        let user = users.find((_user) => _user.email === email)

        if (user) {
          reject(new Error('User already exists'))
          return
        }

        user = {
          id: generateResourceId(),
          avatar: null,
          email,
          name,
          password
        }

        users.push(user)

        const accessToken = sign({ userId: user.id }, JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN
        })

        resolve(accessToken)
      } catch (err) {
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }

  me(accessToken) {
    return new Promise((resolve, reject) => {
      try {
        // Decode access token
        const payload = decode(accessToken)
        console.log(payload)

        resolve(payload)
      } catch (err) {
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }
}

export const authApi = new AuthApi()
